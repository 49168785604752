.custom-date-range-picker-component {
    .custom-input-component .custom-input input {
        color: #101820 !important;
        background-color: #EAEDF2 !important;

        ::placeholder {
            color: #101820 !important;
        }
    }

    .custom-input-component.secondary .MuiInputBase-adornedEnd > div {
        z-index: 1;
    }
}

// div[role=tooltip] {
//     transform: none !important;
//     will-change: initial !important;
//     // top: 50px !important;
// }

.custom-date-range-picker {
    .MuiGrid-item {
        .MuiInput-underline {
            position: relative;
        }
    }
}