/* ICONS */
@import "~material-design-icons/iconfont/material-icons.css";
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css');
// @import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');


/* FONTS */
@import 'fonts/_hk-grotesk.scss';


/* ABSTRACTS */
@import 'abstracts/_variables.scss';
@import 'abstracts/_mixins.scss';


/* BASE */
@import 'base/_typography.scss';
@import 'base/_colors.scss';
@import 'base/_reset.scss';


/* LAYOUT */
@import 'layout/_header.scss';
@import 'layout/_menu-left.scss';


/* COMPONENTS */
@import 'components/_sticky-page-header.scss';
@import 'components/_card.scss';
@import 'components/_table.scss';
@import 'components/_label.scss';
@import 'components/_input.scss';
@import 'components/_button.scss';
@import 'components/_swich.scss';
@import 'components/_dialog.scss';
@import 'components/_alert.scss';
@import 'components/_group-button.scss';
@import 'components/_tooltip.scss';
@import 'components/_checkbox.scss';
@import 'components/_toast.scss';
@import 'components/_circle-icon.scss';
@import 'components/_status-item.scss';
@import 'components/_status-list.scss';
@import 'components/_calendar.scss';
@import 'components/_guide-stepper.scss';
@import 'components/_stepper.scss';
@import 'components/_collapse.scss';
@import 'components/_autosuggest.scss';
@import 'components/_select.scss';
@import 'components/_date-range-picker.scss';
@import 'components/_tabs.scss';
@import 'components/_list.scss';
@import 'components/_avatar-badge.scss';
@import 'components/_cropper.scss';
@import 'components/_rich-text-editor.scss';


/* PAGES */
@import 'pages/_sign-in.scss';
@import 'pages/_sign-up.scss';
@import 'pages/_page-not-found.scss';

@import 'pages/dashboard/_dashboard.scss';
@import 'pages/live-tracking/_live-tracking.scss';

@import 'pages/settings/_onboarding.scss';

.report-header {
    font-weight: 700;
}