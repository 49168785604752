.custom-alert-component {
    .MuiAlert-standardSuccess {
        // background-color: rgba(#64CCC9, 0.10);
        background-color: rgba(#2e86aa, 0.10);

        svg {
            margin-top: 2px;
        }

        * {
            // color: #64CCC9;
            color: #2e86aa;
        }
    }
}