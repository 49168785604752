.custom-autosuggest-component {
    position: relative;
    margin: 1px;

    > div {
        position: relative;
    }

    .react-autosuggest__input--focused {
        outline: none;
    }
    
    .react-autosuggest__input--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    
    .react-autosuggest__suggestions-container {
        display: none;
    }
    
    .react-autosuggest__suggestions-container--open {
        display: block;
        position: absolute;
        width: 100%;
        border: 1px solid #aaa;
        background-color: #fff;
        max-height: 250px;
        // max-height: 100px;
        overflow-y: auto;
        top: 45px;
        z-index: 3;
    }
    
    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 10px 20px;
    }
    
    .react-autosuggest__suggestion--highlighted {
        background-color: #ddd;
    }
    
    .Mui-error {
        border: 1px solid rgba($color-danger, 0.40) !important;
    }

    .MuiInputLabel-asterisk {
        display: none;
    }

    .custom-autosuggest-input-component {
        position: relative;

        .custom-autosuggest-progress {
            position: absolute;
            right: 15px;
            top: 10px;
            z-index: 1;
        }
        
        .custom-autosuggest-close {
            position: absolute;
            right: 16px;
            top: 11px;
            left: unset !important;
            z-index: 2;
            cursor: pointer;

            &:hover {
                color: rgba($color-secondary, 1);
            }
        }

        .custom-autosuggest-search {
            position: absolute;
            right: 16px;
            top: 11px !important;
            left: unset !important;
            z-index: 1;
        }

        .MuiInput-underline {
            background-color: #F7F9FD !important;
        }

        .MuiInput-underline:before, .MuiInput-underline:after {
            content: none !important;
        }
    }

    .add-btn {
        padding: 5px;

        button {
            background-color: $bg-color !important;
            padding-bottom: 0;
            padding-top: 10px;
            padding-bottom: 5px;

            font-size: 15px;
            color: $color-secondary;
            font-weight: normal;

            i {
                position: relative;
                color: $color-active;
                top: 2px;
            }

            div {
                color: $color-active;
                font-weight: 600;
            }
        }
    }
    
    .delete-btn {
        padding: 5px;
        
        &:hover {
            span {
                i {
                    color: $color-danger;
                }
            }
        }
    }

    .view-more-btn {
        padding: 15px 20px 15px 20px;

        a {
            text-decoration: none;
            font-size: 15px;
            color: $color-active;
            font-weight: normal;

            &:hover {
                color: $color-secondary; 
            }
        }
    }

    .custom-placeholder {
        font-style: italic;
        font-size: 12px;
        cursor: default;
    }
    .custom-placeholder-bg {
        background-color: transparent;
        cursor: default;

        &:hover {
            background-color: transparent !important;
        }
    }

    &.no-margin {
        .custom-input-component {
            margin: 0px;
        }
    }

    &.white {
        .custom-input-component {
            background-color: white !important;
        }
    }

    &.import {
        ul {
            li {
                padding-left: 5px !important;
                padding-right: 5px !important;
                padding-top: 2px !important;
                padding-bottom: 2px !important;
                font-size: 12px !important;
            }
        }
    }
}

.react-autosuggest__container {
    .muted {
        color: rgba($muted, 0.4) !important;
    }
}