.custom-toast {
    &.Toastify__toast-container {
        width: auto;
        min-width: 320px;
    }

    .Toastify__toast {
        margin: 10px 0;
        border-radius: 8px;
        min-height: 56px;
        align-items: center;
        display: flex;
    }
    .Toastify__toast--default {
        background-color: white;
        
        .Toastify__toast-body {
            color: $color-secondary;

            span {
                color: $color-secondary;
            }
            
            .material-icons {
                color: $color-active;
            }
        }
    }
    .Toastify__toast--dark {
        .Toastify__toast-body {
            color: white;

            span {
                color: white;
            }
            
            .material-icons {
                color: white;
            }
        }
    }
    .Toastify__toast--info {
        background-color: $color-info;
        
        .Toastify__toast-body {
            color: white;

            span {
                color: white;
            }
            
            .material-icons {
                color: white;
            }
        }
    }
    .Toastify__toast--success {
        background-color: $color-active;
        
        .Toastify__toast-body {
            color: white;

            span {
                color: white;
            }
            
            .material-icons {
                color: white;
            }
        }
    }
    .Toastify__toast--warning {
        background-color: $color-warning;
            
        .Toastify__toast-body {
            color: white;

            span {
                color: white;
            }
            
            .material-icons {
                color: white;
            }
        }
    }
    .Toastify__toast--error {
        background-color: $color-second;
        
        .Toastify__toast-body {
            color: white;

            span {
                color: white;
            }
            
            .material-icons {
                color: white;
            }
        }
    }
    .Toastify__toast-body {
        padding-left: 15px;
        padding-right: 15px;
        margin: 0px;
        font-size: 16px;
        font-family: $font-family-regular;

        > div {
            display: flex;
            align-items: center;
        }
        .material-icons {
            padding-right: 10px;
        }
    }

    .Toastify__close-button {
        align-self: center;
        padding-top: 4px;
    }
}

.modal-group{
    .modal-header{
        font-size: 28px;
        color: #FFFFFF;
        background-color: #df00008e;
        text-align: center;
        margin-top: 0px;
    }
    .modal-title{
        font-size: 18px;
        text-align: center;
        color: #ff0000ab;
    }
    .modal-button{
        text-align: center;
        .modal-button-close{
            font-style: none;
            text-decoration: none;
            color: #FFFFFF;
            background-color: #999;
            padding: 10px 20px;
            margin: auto 10px;
            border-radius: 10px;
        }
        .modal-button-delete{
            font-style: none;
            text-decoration: none;
            color: #FFFFFF;
            background-color: rgba(255, 19, 19, 0.849);
            padding: 10px 20px;
            border-radius: 10px;
            margin: auto 10px;
        }
    }
}

.modal-group-active{
    .modal-header-active{
        font-size: 28px;
        color: #FFFFFF;
        background-color: #5cb85c;
        text-align: center;
        margin-top: 0px;
    }
    .modal-title-active{
        font-size: 18px;
        text-align: center;
        color: #5cb85c;
    }
    .modal-button-active{
        text-align: center;
        .modal-button-close{
            font-style: none;
            text-decoration: none;
            color: #FFFFFF;
            background-color: #999;
            padding: 10px 20px;
            margin: auto 10px;
            border-radius: 10px;
        }
        .modal-button-active{
            font-style: none;
            text-decoration: none;
            color: #FFFFFF;
            background-color: #5cb85c;
            padding: 10px 20px;
            border-radius: 10px;
            margin: auto 10px;
        }
    }
}

.Quota-group{
    margin:10px;
    .Quota-head{
        font-size: 22px;
        color: #565553;
        text-align: center;
        margin-top: 0px;
    }
    .Quota-title{
        font-size: 16px;
        text-align: center;
        color: #565553;
    }
    .Quota-input{
        font-size: 28px;
        text-align: center;
        color: #565553;
        display: block;
        width:100px;
        height:50px;
        justify-content: center;
        margin: auto;
        border: 1px solid #c4c4c4 !important;
        border-radius: 5px;
    }
    .Quota-button{
        text-align: center;
        .Quota-button-close{
            font-style: none;
            text-decoration: none;
            color: #FFFFFF;
            background-color: #999;
            padding: 10px 20px;
            margin: auto 10px;
            border-radius: 10px;
        }
        .Quota-button-create{
            font-style: none;
            text-decoration: none;
            color: #FFFFFF;
            background-color: #29b6f6;
            padding: 10px 20px;
            border-radius: 10px;
            margin: auto 10px;
        }
    }
}