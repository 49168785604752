.custom-sticky-page-header {
    min-height: 98px;
    background-color: white;
    position: sticky;
    top: 56px;
    border-bottom: 1px solid #EAEDF2;
    z-index: 20;
    display: flex;
    align-items: center;

    &.top-0 {    
        top: 0px;
    }

    &.small {
        min-height: 75px;
    }

    .MuiDivider-vertical {
        height: 19px;
        background-color: #E0E0E0;
        margin-right: 15px;
    }
}