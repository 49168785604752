.custom-list-component {
    .MuiListSubheader-sticky {
        position: relative;
    }
    nav {
        padding: 0px;
    }
    .custom-list-item {
        &.sub-item {
            background-color: #F5F6F9;

            .MuiListItemText-root {
                span {
                    font-weight: bold;
                }
            }
        }

        // &.active {
        //     background-color: $color-active;
        // }

        &.custom-item {
            &.active {
                background-color: rgba($color-active, 0.2);
            }
        }
    }
}