.custom-collapse-component {
    &.job-steps-row {
        border: 1px solid #EEEFF2;
        border-radius: 6px;

        .custom-collapse-item {
            min-height: 56px;
            display: flex;
            align-items: center;
        }

        .custom-collapse-details {
            min-height: 56px;
            background-color: #FAFBFC;
        }
    }

    &.route-optimize-row {
        border: 1px solid #EEEFF2;
        border-radius: 6px;
        margin-bottom: 15px;

        .custom-collapse-item {
            display: flex;
            align-items: center;
        }

        .custom-collapse-details {
            background-color: #FAFBFC;
        }
    }
}