.signup {
    display: flex;
    height: 100%;
    background-color: #FFFFFF;
    .top-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include respond-below('sm') {
            flex-direction: column;
        }
        .logo {
            margin: 32px 56px;
            img {
                width: 79px;
            }
        }
        .signin-acc {
            display: flex;
            align-items: inherit;
            margin: 0 32px;
            div {
                margin-right: 16px;
                font-size: 16px;
            };
        }
    }
    .signup-left {
        max-width: 440px;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
        z-index: 3;
        @include respond-below('lg') {
            position:absolute;
            top: 0%;
            left: 50%;
            margin-left: -220px;
        }
        .logo {
            margin-top: 50px; 
            margin-bottom: 50px; 
            text-align: start;
        }
        .form-title {
            text-align: left;
            font-size: 36px;
            color: black;
            margin: 16px 0;
        }
        .form-description {
            font-size: 18px;
            text-align: left;
            margin: 16px 0;
            color: black;
        }
        .form-wrapper {
            max-width: 368px;
            margin: 0 auto;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center; 
        }  
        .sign-in {
            padding-top: 20px;
        }  
    }
    .signup-right {
        width: 100%;
        background-color: #F5F6F9;
        @include respond-below('lg') {
            display: none;
        }
        .form-warp{
            background: transparent url('../../img/background/signup.jpg') 0% 0% no-repeat padding-box;
            width:100%;
            height: 540px;
        .form-title {
            text-align: center;
            padding-top:100px !important;
            h2{
                color:#ffffff;
                font-size:28px;

            }
            p {
                color:#ffffff;
                font-size:22px;

            }
        }
        .form-description {
            color:#ffffff;
            padding:80px 60px 100px 60px;
            text-align: center;
            font-size:22px;
        }
        
    }
}

 .right-Group {
    @include respond-below('lg') {
        display: none;
    }
        .right-header{
            text-align: center;
            font-size: 28px;
            color: #545454;
            font-weight: bold;
            margin: 35px auto;
        }
        .right-title {
            text-align: center;
            font-size: 18px;
            color: #545454;
            margin-top:20px;
        }
        .right-warper {
            margin: auto;
            align-items: center;
            justify-content: center;
            width:220px;
            padding:0px 20px;
            margin-top: 40px;
            margin-bottom: 40px;
            .right-warper-img{
                text-align: center;
            }
            h2{
                text-align: center;
                font-size: 22px;
                color: #545454; 
            }
            p{
                text-align: center;
                font-size: 18px;
                color: #545454;
            }
            &.middle {
                border-left:1px solid #b8b8b8d2;
                border-right:1px solid #b8b8b8d2;
            }
        }
 }
   
}
