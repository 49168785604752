﻿.custom-menu-left {
    hr {
        border: 1px solid #969696;
    }

    .MuiDrawer-paper {
        background: #555555 0% 0% no-repeat padding-box;

        nav {
            .custom-logo {
                margin: auto;
                justify-content: center;
                align-self: center;
                align-items: center;
                text-align: center;
            }

            .custom-item {
                padding: 13px 20px;

                a {
                    padding: 5px 15px;
                    border-radius: 7px;
                    display: flex !important;
                    width: 100%;
                    display: contents;
                    text-decoration: none;
                    position: relative;

                    i, svg {
                        padding-right: 10px;
                    }

                    span, i {
                        color: #ADAFB3;
                    }

                    svg {
                        width: 35px;
                        margin-top: 2px;
                        fill: #ADAFB3;

                        rect, g, path {
                            fill: #ADAFB3;
                        }
                    }

                    .custom-expand-right {
                        height: 18px;
                        padding-right: 0px;
                        right: 0;
                        margin-top: 0px;
                        padding-top: 5px;
                        position: absolute;
                    }
                    .custom-expand-down {
                        height: 15px;
                        padding-right: 0px;
                        right: 0;
                        margin-top: 0px;
                        padding-top: 7px;
                        position: absolute;
                    }

                    .no-icon {
                        width: 34px;
                    }
                }
                
                &:hover {
                    a {
                        background: #75787B 0% 0% no-repeat padding-box;

                        span, i {
                            color: #FFFFFF;
                        }

                        svg {
                            fill: #FFFFFF !important;
    
                            rect, g, path {
                                fill: #FFFFFF !important;
                            }
                        }
                    }
                }
                &.active {
                    a {
                        background: #75787B 0% 0% no-repeat padding-box;

                        span, i {
                            color: #FFFFFF;
                        }

                        svg {
                            fill: #FFFFFF !important;
    
                            rect, g, path {
                                fill: #FFFFFF !important;
                            }
                        }
                    }
                }
            }

            .sub-nav {
                background: #424242 0% 0% no-repeat padding-box;

                .custom-item {
                    padding: 5px 20px;

                    &:hover {
                        a {
                            background: #424242 0% 0% no-repeat padding-box;
    
                            span, i {
                                color: #FFFFFF;
                            }

                            svg {
                                fill: #FFFFFF !important;
        
                                rect, g, path {
                                    fill: #FFFFFF !important;
                                }
                            }
                        }
                    }

                    &.active {
                        a {
                            background: #424242 0% 0% no-repeat padding-box;
    
                            span, i {
                                color: #FFFFFF;
                            }

                            svg {
                                fill: #FFFFFF !important;
        
                                rect, g, path {
                                    fill: #FFFFFF !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

img.service-icon {
    display: block;
    width: 36px;
    margin-right: 10px;
    object-fit: contain;
}

.dark-select {
    background-color: #444 !important;
    color: #fff !important;
    font-size: 12px !important;
    transition: all .3s ease-in;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    white-space: initial !important;

    &:hover {
        background-color: darken(#444, 10%) !important;
    }
}

.MuiMenu-list {
    padding: 0 !important;
}

#servicesMenuSelector {
    padding: 0 15px;

    .MuiSelect-icon {
        color: #fff !important;
    }

    label {
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        display: block;
        margin-bottom: 5px;
    }

    .service-icon {
        float: left;
        width: 32px;
        height: 32px;
        border-radius: 7px;
        margin-right: 10px;
        display: block;
    }

    .MuiInputBase-root {
        background-color: #424242;
        border-radius: 7px;
        color: #fff;
        font-size: 12px;
        line-height: 16px;
        width: 100%;
    }

    .MuiInput-underline {
        &:before, &:after {
            border-bottom: none;
        }
    }

    .MuiSelect-selectMenu {
        padding: 15px;
        white-space: normal;
    }
}