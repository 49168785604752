.custom-status-list-component {
    position: relative;
    width: 100%;

    ul {
        margin: 0px;
        padding: 0px;
        text-align: right;
        list-style-type: none;

        li {
            position: relative;
            display: inline-block;
            margin-left: 15px;
            margin-right: 15px;
            border-bottom: 2px solid transparent;
            padding-bottom: 13px;
            cursor: pointer;

            &:hover, &.active {
                border-bottom: 2px solid $color-second;

                &:after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-bottom: 8px solid $color-second;
                    left: 45%;
                    bottom: 0px;
                }
            }
        }
    }
}

.custom-status-list-wrapper {
    position: relative;
}