.custom-guide-stepper-component {
    > div {
        padding: 20px 0px 10px 0px;
    }

    .MuiStepConnector-line {
        height: 1px;
        background-color: rgba(0, 0, 0, 0.38);
    }

    &.primary {
        .MuiStepLabel-root {
            .MuiStepLabel-iconContainer {
                .custom-step-icon {
                    background-color: $color-active;
                    color: white;
                }
            }
        }
    }

    &.secondary {
        .MuiStepLabel-root {
            .MuiStepLabel-iconContainer {
                .custom-step-icon {
                    background-color: $color-second;
                    color: white;
                }
            }
        }
    }
}