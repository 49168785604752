.custom-stepper-component {
    .MuiStepLabel-root {
        position: relative;
        align-items: end;
        padding-bottom: 50px;
        
        &:before {
            content: '';
            width: 4px;
            height: 100%;
            background-color: #EEEFF2;
            position: absolute;
            top: 0;
            left: 14px;
        }

        .MuiStepLabel-iconContainer {
            z-index: 1;
            padding-right: 25px;

            .custom-step-icon {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: $color-default;
                color: $color-secondary;
                font-family: $font-family-bold;
            }

            .custom-step-icon-null {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: transparent;
                color: transparent;
            }
        }
    }

    .MuiStep-root {
        &:first-child {
            .MuiStepLabel-root {
                padding-bottom: 20px;

                &:before {
                    content: none;
                }
            }
        }

        &:last-child {
            .MuiStepLabel-root {
                &:before {
                    content: none;
                }
            }
        }
    }

    &.primary {
        .MuiStepLabel-root {
            .MuiStepLabel-iconContainer {
                .custom-step-icon {
                    background-color: $color-active;
                    color: white;
                }
            }
        }
    }

    &.secondary {
        .MuiStepLabel-root {
            .MuiStepLabel-iconContainer {
                .custom-step-icon {
                    background-color: $color-second;
                    color: white;
                }
            }
        }
    }
}