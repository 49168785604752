.custom-swich-component {
    padding: 12px 0px;
    
    .react-switch-bg {
        div {
            &:first-child {
                span {
                    position: absolute;
                    top: 9px;
                    left: 15px;
                    font-weight: bold;
                    color: $white;
                }
            }
    
            &:last-child {
                span {
                    position: absolute;
                    top: 9px;
                    left: 10px;
                    font-weight: bold;
                    color: $color-secondary;
                }
            }
        }
    }
}