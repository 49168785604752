﻿/* SCROLL */
::-webkit-scrollbar {
    // display: block;
    // width: 8px;
    // height: 16px;
}

::-webkit-scrollbar-thumb {
    background: $gray;
}
/* END SCROLL */


/* BORDER */
.no-border {
    border: 0 !important;
}
/* END BORDER */


/* RADIUS */
.no-border-radius {
    border-radius: 0 !important;
}

.square-border {
    border-radius: 0 !important;
}

.circle {
    border-radius: 50%;
}

.border-radius-50 {
    border-radius: 50px;
}

.border-radius-5 {
    border-radius: 5px;
}
/* END RADIUS */


/* MARGIN */
.m-0-auto {
    margin: 0 auto;
}
/* END MARGIN */


/* PADDING */
.pb-0 {
    padding-bottom: 0px !important;
}
/* END PADDING */


/* POSITIONS */
.position-relative {
    position: relative !important;
}
.position-absolute {
    position: absolute !important;
}
.jumbotron-center {
    position: absolute;
    z-index: 1;
    top: 0;
}
/* END POSITIONS */


/* CURSOR */
.cursor-pointer, 
.cursor-hand {
    cursor: pointer;
}

.no-cursor {
    cursor: default;
}
/* END CURSOR */


/* UNDERLINE */
.no-underline {
    text-decoration: none !important;
}
/* END UNDERLINE */


/* OVERFLOW */
.overflow-hidden {
    overflow: hidden;
}
/* END OVERFLOW */


/* Z INDEX */
.no-z-index {
    z-index: unset !important;
}
/* END Z INDEX */


/* DIV IMG */
.div-img-contain {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: contain !important;
}

.div-img-cover {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
}
/* END DIV IMG */


/* SHADOW */
.shadow {
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.no-shadow {
    -webkit-box-shadow: unset !important;
    -moz-box-shadow: unset !important;
    box-shadow: unset !important;
}
/* END SHADOW */


/* VERTICAL ALIGN */
.v-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.v-left-center {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.v-right-center {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.v-bottom-right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
/* END VERTICAL ALIGN */


/* DOTS */
.dots {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    line-height: 15px;
    font-size: 13px;
    font-weight: 500 !important;
}
/* END DOTS */


/* KBD */
kbd {
    background-color: #eee;
    border-radius: 3px;
    border: 1px solid #b4b4b4;
    color: #333;
    display: inline-block;
    font-size: .85em;
    font-weight: 700;
    padding: 2px 4px;
    white-space: nowrap;
}
/* END KBD */


/* ELLIPSIS */
.ellipsis-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ellipsis-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ellipsis-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.ellipsis-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.ellipsis-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}
/* END ELLIPSIS */


/* ANIM ROTATE */
.rotate {
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -o-transition: all .3s linear;
    -ms-transition: all .3s linear;
    transition: all .3s linear;
}

.rotate.down {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
/* END ANIM ROTATE */


.live-tracking {
    background-color: white;

    main {
        height: 100%;
    }

    .infoBox {
        img {
            position: absolute !important;
            right: 5px;
            top: 3px;
        }
    }
}