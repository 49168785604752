.group-button-component {
    .MuiButtonGroup-contained {
        box-shadow: unset;
    }

    button {
        border-radius: 4px;
        background-color: unset;
        height: 43px;
        min-width: 43px;
        box-shadow: unset;
        padding: 6px 12px;
        border-radius: 0px;
        border-left: 0px !important;
        border-right: 0px !important;
        border-bottom: 2px solid transparent;
        text-transform: unset;

        .MuiButton-label {
            text-transform: none;
            color: $color-dark;
            font-size: 14px;
            font-family: $font-family-medium;

            span, div, i, svg {
                text-transform: none;
                color: $color-dark;
                font-size: 14px;
                font-family: $font-family-medium;
            }
            svg {
                fill: rgba($color-dark, 0.7);
    
                rect, g, path {
                    fill: rgba($color-dark, 0.7);
                }
            }

            &:hover {
                background-color: unset;
            }
        }

        &:hover {
            background-color: unset;
            box-shadow: unset;
            border-bottom: 2px solid $color-dark;
        }

        &.MuiButton-containedPrimary, &.MuiButton-textPrimary {
            background-color: unset;
            border-bottom: 2px solid transparent;

            &:hover {
                background-color: unset;
                color: $color-active;
                border-bottom: 2px solid $color-active;

                span, div, i, svg {
                    color: $color-active;
                }
            }
        }
        &.MuiButton-containedSecondary, &.MuiButton-textSecondary {
            background-color: unset;
            border-bottom: 2px solid transparent;

            &:hover {
                background-color: unset;
                color: $color-second;
                border-bottom: 2px solid $color-second;

                span, div, i, svg {
                    color: $color-second;
                }
            }
        }

        &.MuiButton-sizeSmall {
            height: 35px;
        }
        &.MuiButton-sizeLarge {
            height: 50px;
        }


        &.selected {
            &.MuiButton-containedPrimary, &.MuiButton-textPrimary {
                background-color: unset;
                color: $color-active;
                border-bottom: 2px solid $color-active;
    
                span, div, i, svg {
                    color: $color-active;
                }
                
                &:hover {
                    background-color: unset;
                    color: $color-active;
                    border-bottom: 2px solid $color-active;
    
                    span, div, i, svg {
                        color: $color-active;
                    }
                }
            }
            &.MuiButton-containedSecondary, &.MuiButton-textSecondary {
                background-color: unset;
                color: $color-second;
                border-bottom: 2px solid $color-second;
    
                span, div, i, svg {
                    color: $color-second;
                }

                &:hover {
                    background-color: unset;
                    color: $color-second;
                    border-bottom: 2px solid $color-second;
    
                    span, div, i, svg {
                        color: $color-second;
                    }
                }
            }
        }
    }

    .Mui-disabled {
        background-color: rgba($color-secondary, 0.3) !important;

        .MuiButton-label {
            color: $color-secondary !important;

            span, div, i, svg {
                color: $color-secondary !important;
            }
            svg {
                fill: rgba($color-secondary, 0.7) !important;
    
                rect, g, path {
                    fill: rgba($color-secondary, 0.7) !important;
                }
            }

            &:hover {
                color: rgba($color-secondary, 0.8) !important;

                span, div, i, svg {
                    color: rgba($color-secondary, 0.8) !important;
                }
            }
        }
    }

    
    &.contained {
        button {
            background-color: unset;
            border-bottom: 0px solid transparent;
    
            &:hover {
                background-color: $color-dark;
                box-shadow: unset;
                border-bottom: 0px solid transparent;
            }
    
            &.MuiButton-containedPrimary, &.MuiButton-textPrimary {
                background-color: unset;
                border-bottom: 2px solid transparent;
    
                &:hover {
                    background-color: $color-active;
                    color: $color-active;
                    border-bottom: 0px solid transparent;
    
                    span, div, i, svg {
                        color: white;
                    }
                }
            }
            &.MuiButton-containedSecondary, &.MuiButton-textSecondary {
                background-color: unset;
                border-bottom: 2px solid transparent;
    
                &:hover {
                    background-color: $color-second;
                    color: $color-second;
                    border-bottom: 0px solid transparent;
    
                    span, div, i, svg {
                        color: white;
                    }
                }
            }
    
            &.selected {
                &.MuiButton-containedPrimary, &.MuiButton-textPrimary {
                    background-color: $color-active;
                    color: $color-active;
                    border-bottom: 0px solid transparent;
        
                    span, div, i, svg {
                        color: white;
                    }
                    
                    &:hover {
                        background-color: $color-active;
                        color: $color-active;
                        border-bottom: 0px solid transparent;
        
                        span, div, i, svg {
                            color: white;
                        }
                    }
                }
                &.MuiButton-containedSecondary, &.MuiButton-textSecondary {
                    background-color: $color-second;
                    color: $color-second;
                    border-bottom: 0px solid transparent;
        
                    span, div, i, svg {
                        color: white;
                    }
    
                    &:hover {
                        background-color: $color-second;
                        color: $color-second;
                        border-bottom: 0px solid transparent;
        
                        span, div, i, svg {
                            color: white;
                        }
                    }
                }
            }
        }

        &.rounded {
            button {
                border-radius: 4px !important;
            }
        }

        &.h-25 {
            button {
                height: 25px;
            }
        }
    }

    &.head-tabs {
        position: absolute;
        bottom: 0px;

        button {
            height: auto;
            padding: 0px;
            margin-right: 15px;
        }
    }
}