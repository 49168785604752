.custom-avatar-badge-component {
    .MuiBadge-badge {
        width: 10px !important;
        height: 10px !important;
        border-radius: 50% !important;
    }

    .MuiBadge-colorPrimary {
        background-color:  #2e86aa !important;
        // background-color: #4CE095 !important;
    }

    .MuiBadge-colorSecondary {
        background-color: #565553 !important;
        // background-color: #EC4545 !important;
    }
}