.custom-tooltip-component {
    font-size: 25px !important;
    position: absolute;
    top: -1px;
    margin-left: 10px;
    color: rgba($color-secondary, 0.60) !important;
    cursor: help;

    &.txt-dark {
        color: $color-secondary !important;
    }

    &-span {
        font-size: 12px !important;
        color: rgba($color-secondary, 0.60) !important;
    }
}

div {
    &[role="tooltip"] {
        pointer-events: unset !important;
        z-index: 99999;
    }
}

//  ----------------------------------------------------------------
//  Tooltip Style Lib
//  ----------------------------------------------------------------

//Color-palete
$black: #000;
$white: #fff;
// $worknode-theme-color: #64CCC9;
$worknode-theme-color: #2e86aa;

//Primary-color
$tooltip-base-text-color: $white;
$tooltip-base-bg-color: $black;
//Types
$tooltip-danger-bg-color: $worknode-theme-color;
//Features

.preview-title {
    margin: 40px 0 20px;
    font-size: 24px;
    font-weight: normal;
  }
  
  .tooltip-content {
    max-width: 960px;
    margin: 0 auto;
    padding: 30px 60px;
    > span {
      font-size: 24px;
      display: block;
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
  
  [data-tooltip] {
    position: relative;
    &:before,
    &:after {
      opacity: 0;
      position: absolute;
      left: 50%;
      backface-visibility: hidden
    }
    &:before {
      border-style: solid;
      border-top-width: 4px;
      border-right-width: 4px;
      border-bottom-width: 4px;
      border-left-width: 4px;
      border-bottom-width: 0;
      content: '';
      top: -2px;
      width: 0;
      height: 0;
      transform: translate(-50%, calc(-50% - 6px));
      transition: opacity .1s cubic-bezier(.73, .01, 0, 1) 0s, transform .6s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) 0s;
      z-index: 110000
    }
    &:after {
      content: attr(data-tooltip);
      text-align: center;
      padding: 10px 10px 9px;
      font-size: 16px;
      border-radius: 8px;
      color: $tooltip-base-text-color;
      transition: opacity .3s cubic-bezier(.73, .01, 0, 1), transform .3s cubic-bezier(.73, .01, 0, 1), -webkit-transform .3s cubic-bezier(.73, .01, 0, 1);
      pointer-events: none;
      z-index: 999;
      white-space: nowrap;
      bottom: 100%;
      transform: translate(-50%, 12px);
      max-width: 320px;
      text-overflow: ellipsis;
      overflow: hidden
    }
  }
  
  [data-tooltip]:after {
    background: $tooltip-base-bg-color;
  }
  
  [data-tooltip]:before {
    border-top-color: $tooltip-base-bg-color;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent
  }
  
  [data-tooltip] {
    &:focus,
    &:hover {
      &:before,
      &:after {
        opacity: 1;
      }
    }
    &:focus,
    &:hover {
      &:before {
        transition: opacity .1s cubic-bezier(.73, .01, 0, 1) .1s, transform .6s cubic-bezier(.73, .01, 0, 1) .1s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) .1s;
        transform: translate(-50%, calc(-50% - 2px));
      }
      &:after {
        transform: translate(-50%, -6px);
      }
    }
  }
  
  
  [data-tooltip][data-tooltip-conf*=danger]:after {
    background: $tooltip-danger-bg-color;
  }
  
  [data-tooltip][data-tooltip-conf*=danger]:before {
    border-top-color: $tooltip-danger-bg-color;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent
  }
  
  [data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=right]:before {
    border-top-color: transparent;
    border-right-color: $tooltip-danger-bg-color;
    border-bottom-color: transparent;
    border-left-color: transparent
  }
  
  [data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=bottom]:before {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: $tooltip-danger-bg-color;
    border-left-color: transparent
  }
  
  [data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=left]:before {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: $tooltip-danger-bg-color;
  }
  
  [data-tooltip][data-tooltip=''] {
    &:after,
    &:before {
      display: none
    }
  }
  