﻿body {
    background-color: $bg-color;
}

h1, h2, h3, h4, h5, h6,
p, span, label, div, th, td {
    color: $color-secondary;
}


/* BG */
.no-bg {
    background: none !important;
}

.bg-transparent {
    background: transparent !important;
}

.bg-white {
    background-color: $white !important;
}

.txt-dark {
    color: $color-secondary !important;
}

.bg-color {
    background-color: $bg-color !important;
}

.bg-color-default {
    background-color: $color-default !important;
}

.bg-primary {
    background-color: $color-primary !important;
}
.bg-second {
    background-color: $color-second !important;
}
/* END BG */


/* TEXT */
.text-transparent {
    color: $transparent !important;
}

.text-white {
    color: $white !important;
}

.text-black {
    color: $black !important;
}

.text-active, .txt-active {
    color: $color-active !important;
}
.text-second, .txt-second {
    color: $color-second !important;
}

.text-warning, .txt-warning {
    color: $color-warning !important;
}
.text-info, .txt-info {
    color: $color-info !important;
}
.text-danger, .txt-danger {
    color: $color-danger !important;
}
.text-color-default {
    color: $color-default !important;
}
/* END TEXT */


/* OTHERS */
.muted {
    color: $muted !important;
}
.lightGray {
    color: darkgray !important;
}

.icon-white {
    .MuiIconButton-label {
        color: white;
    }
}

.skeleton-color {
    color: $skeleton-gray !important;
}

.facebook-color {
    color: $facebook !important;
}
.whatsapp-color {
    color: $whatsapp !important;
}
.linkedin-color {
    color: $linkedin !important;
}
.twitter-color {
    color: $twitter !important;
}

.pdf-color {
    color: $pdf !important;
}

.word-color {
    color: $word !important;
}

.excel-color {
    color: $excel !important;
}
/* END OTHERS */